import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g transform="translate(-8.000000, -2.000000)">
      <g transform="translate(11.000000, 5.000000)">
        <path
          d="M 51 45 C 51 46 51 49 51 52 C 50.6917 57.95 46.6917 61 38 61 C 36.1945 60.6667 32 61 28 58 L 28 30 L 51 30 C 51 30 51 33 51 39 C 51 42 51 44 51 45 Z Z M 28 48 C 28 50.6667 28 53.3333 28 54 C 32 56.3333 35 57 38 57 C 43.6917 56.5 45.5917 54.75 46 52 C 46 49 46 47 46 34 H 40 L 40 30 L 28 30 M 28 30 Z"
          fill="currentColor"
        />
        <polygon
          id="Shape"
          stroke="currentColor"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="39 0 0 22 0 67 39 90 78 68 78 23"
        />
      </g>
    </g>
  </svg>
);

export default IconLogo;
